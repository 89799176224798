import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, DialogActions, DialogContent, DialogTitle, Divider, Fade, IconButton, Button, TextField, useMediaQuery, useTheme } from "@mui/material";
import FlipRoundedIcon from "@mui/icons-material/FlipRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useSync } from "../../hooks/sync";
import AppDialog from "../common/AppDialog";
import { updateLastRefreshed } from "../../redux/features/deck/deckSlice";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import { modules, formats } from "../../utilities/quillConfig";

const CreateCard = ({ open, onClose, onSubmit }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLg = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const sync = useSync();
  const [showBack, setShowBack] = useState(false);
  const [sideA, setSideA] = useState("");
  const [sideB, setSideB] = useState("");
  // const [editorHeight, setEditorHeight] = useState("300px");

  // useEffect(() => {
  //   if (isXs) {
  //     setEditorHeight("500px");
  //   } else if (isMd) {
  //     setEditorHeight("400px");
  //   } else if (isLg) {
  //     setEditorHeight("300%");
  //   }
  // }, [isXs, isMd, isLg]);

  useEffect(() => {
    // Reset states when the dialog is opened
    if (open) {
      setShowBack(false);
      setSideA("");
      setSideB("");
    }
  }, [open]);

  const handleFlip = () => {
    setShowBack(!showBack);
  };

  const handleSave = async () => {
    try {
      const sanitizedSideA = DOMPurify.sanitize(sideA);
      const sanitizedSideB = DOMPurify.sanitize(sideB);
      onSubmit(sanitizedSideA, sanitizedSideB);
      const newRefreshTime = new Date().toISOString();
      dispatch(updateLastRefreshed(newRefreshTime));
      await sync();
    } catch (error) {
      console.error("Error occurred while saving card:", error);
    } finally {
      setSideA("");
      setSideB("");
    }
  };

  const handleClose = () => {
    setSideA("");
    setSideB("");
    onClose();
  };

  return (
    <AppDialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {showBack ? "Back" : "Front"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>
      <Divider />

      <DialogContent
        sx={{
          minHeight: "auto",
          maxHeight: "calc(100vh - 96px)",
          overflow: "hidden",
          padding: 0,
          // border: "solid",
          // borderColor: "purple",
        }}>
        <Box
          sx={{
            overflow: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            overflow: "hidden",
            // border: "solid",
            // borderColor: "green",
          }}>
          {showBack ? (
            <ReactQuill
              key="sideB"
              theme="snow"
              value={sideB}
              modules={modules}
              formats={formats}
              onChange={(value) => setSideB(value)}
              style={{
                width: "100%",
              }}
            />
          ) : (
            <ReactQuill
              key="sideA"
              theme="snow"
              value={sideA}
              modules={modules}
              formats={formats}
              onChange={(value) => setSideA(value)}
              style={{
                width: "100%",
              }}
            />
          )}
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          padding: "0px 24px 20px 24px",
        }}>
        <Button variant="outlined" startIcon={<FlipRoundedIcon />} onClick={handleFlip} fullWidth>
          Flip
        </Button>
        <Button type="submit" variant="contained" color="primary" onClick={handleSave} fullWidth>
          Save
        </Button>
      </DialogActions>
    </AppDialog>
  );
};

export default CreateCard;
