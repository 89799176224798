import React from "react";
import { Dialog } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

const AppDialog = ({ children, open, onClose, maxWidth = "md" }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLandscape = useMediaQuery("(orientation: landscape) and (max-width: 932px)");

  const fullScreen = isMobile || isLandscape;
  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          onClose(event, reason);
        }
      }}
      fullWidth
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      PaperProps={{
        sx: {
          borderRadius: fullScreen ? "0" : "1rem",
          userSelect: "none",
        },
      }}>
      {children}
    </Dialog>
  );
};

export default AppDialog;
