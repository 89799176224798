import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Fade, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import RecyclingRoundedIcon from "@mui/icons-material/RecyclingRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import db from "../../database/db";
import { findAllDecksByUserId } from "../../services/data/deckDataService";
import BreadcrumbsNav from "../../components/common/BreadcrumbsNav";
import PaperList from "../../components/list/PaperList";
import PaperListPlaceholder from "../../components/list/PaperListPlaceholder";
import DeleteDeck from "../../components/dialogs/DeleteDeck";
import DropdownMainMenu from "../../components/menus/DropdownMainMenu";
import { setError } from "../../redux/features/error/errorSlice";

const DecksBinScreen = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const lastSynced = useSelector((state) => state.syncing.lastSynced);
  const daysBeforeDeletion = useSelector((state) => state.syncing.daysBeforeDeletion);
  const [decks, setDecks] = useState([]);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deckToDelete, setDeckToDelete] = useState(null);

  const toggleEdit = () => {
    setIsEditClicked((prev) => !prev);
  };

  const openDeleteDialog = (deckId) => {
    setDeckToDelete(deckId);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (deckToDelete) {
        await handleHardDelete(deckToDelete);
      }
      setDeleteDialogOpen(false);
    } catch (error) {
      dispatch(setError("Couldn't delete deck. Please try again"));
      console.error("ERROR: ", error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    try {
      const getDecks = async () => {
        if (!userId) return;

        const loadedDecks = await findAllDecksByUserId(userId, true);
        setDecks(loadedDecks);
      };
      getDecks();
    } catch (error) {
      dispatch(setError("Couldn't load decks. Please try again"));
      console.error("ERROR: ", error);
    }
  }, [userId, lastSynced]);

  const handleRestore = async (deckId) => {
    try {
      const deck = await db.decks.get(deckId);

      if (!deck) {
        return;
      }

      deck.deletedOn = null;
      deck.modifiedOn = new Date().toISOString();

      await db.decks.put(deck);

      const updatedDecks = await findAllDecksByUserId(userId, true);
      setDecks(updatedDecks);
    } catch (error) {
      dispatch(setError("Couldn't restore deck. Please try again"));
      console.error("ERROR: ", error);
    }
  };

  const handleHardDelete = async (deckId) => {
    try {
      const deck = await db.decks.get(deckId);

      if (!deck) {
        return;
      }

      const now = new Date();
      const deletionDate = new Date(now);
      deletionDate.setDate(deletionDate.getDate() + daysBeforeDeletion);

      const cards = await db.cards.where({ deckId }).toArray();
      const updatePromises = cards.map((card) => {
        return db.cards.update(card.id, {
          isDeleted: true,
          deletedOn: deletionDate.toISOString(),
          modifiedOn: now.toISOString(),
        });
      });
      await Promise.all(updatePromises);

      deck.deletedOn = deletionDate.toISOString();
      deck.isDeleted = true;
      deck.modifiedOn = now.toISOString();

      await db.decks.put(deck);

      const updatedDecks = await findAllDecksByUserId(userId, true);
      setDecks(updatedDecks);
    } catch (error) {
      dispatch(setError("Couldn't delete deck. Please try again"));
      console.error("ERROR: ", error);
    }
  };

  const getCharLimit = () => (isSmallScreen ? 25 : 55);

  const deckItemText = (deck) => {
    try {
      const charLimit = getCharLimit();
      return (
        <Typography variant="body1" sx={{ fontSize: { xs: "1rem", md: "1.1rem" } }}>
          {deck.name.length > charLimit ? deck.name.substring(0, charLimit) + "..." : deck.name}
        </Typography>
      );
    } catch (error) {
      dispatch(setError("An issue occurred while getting deck item text. Please try again"));
      console.error("ERROR: ", error);
      return null;
    }
  };

  const deckSecondaryAction = (deck) => (
    <Fade in={isEditClicked} timeout={300} unmountOnExit>
      <Stack direction="row" spacing={0.25}>
        <IconButton edge="end" aria-label="restore" onClick={() => handleRestore(deck.id)}>
          <RestoreRoundedIcon color="primary" />
        </IconButton>
        <IconButton edge="end" aria-label="delete" onClick={() => openDeleteDialog(deck.id)}>
          <DeleteRoundedIcon color="error" />
        </IconButton>
      </Stack>
    </Fade>
  );

  return (
    <Stack data-testid="decks-bin-screen" spacing={1.5}>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Box display="flex" alignItems="center">
          <BreadcrumbsNav />
        </Box>
        <Stack direction="row" spacing={1}>
          <Stack direction="row" spacing={2} alignItems="center">
            {isSmallScreen && <DropdownMainMenu />}

            <Typography
              color="primary"
              onClick={toggleEdit}
              sx={{
                cursor: "pointer",
                color: "text.primary",
              }}>
              {isEditClicked ? "Done" : "Edit"}
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <Typography
        variant="h6"
        component="div"
        sx={{
          fontSize: "1.5rem",
          fontWeight: "600",
          maxWidth: {
            xs: "300px",
            sm: "500px",
          },
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}>
        Recently Deleted
      </Typography>

      {decks.length === 0 ? (
        <PaperListPlaceholder icon={RecyclingRoundedIcon} primaryText="All cleaned up!" secondaryText="Decks you delete will appear here and be permanently deleted after 30 days." />
      ) : (
        <PaperList
          items={decks}
          onItemSelect={() => {}} // TODO: Remove as not required
          isEditClicked={isEditClicked}
          ItemIcon={LayersRoundedIcon}
          secondaryAction={deckSecondaryAction}
          itemText={deckItemText}
          itemHeight="3rem"
          dividerInsetMargin="3rem"
        />
      )}
      <DeleteDeck open={deleteDialogOpen} onClose={handleCloseDeleteDialog} onDelete={handleConfirmDelete} deckName={decks.find((deck) => deck.id === deckToDelete)?.name || ""} />
    </Stack>
  );
};

export default DecksBinScreen;
